import { PublicClientApplication } from "@azure/msal-browser";
// Msal Configurations
const config = {
  auth: {
    authority:
      "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22",
    clientId: "bba2b81e-f960-47d8-bda9-a202cb0e4283",
    redirectUri: "https://error.art-training.ciostage.accenture.com",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: ["user.read"],
};


export const authProvider = new PublicClientApplication(config);
