import React from "react";
import ErrorLogin from "./Error";
//import { MsalAuthenticationTemplate} from "@azure/msal-react";
//import { InteractionType } from "@azure/msal-browser";

function App() {


  return (
  // <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ErrorLogin />
   // </MsalAuthenticationTemplate>
  )
}

export default App;
