import App from "./App";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider, MsalAuthenticationTemplate} from "@azure/msal-react";
import { authProvider, authenticationParameters } from "./authProvider";
import { InteractionType } from "@azure/msal-browser";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <MsalProvider instance={authProvider}>
  <MsalAuthenticationTemplate 
    interactionType={InteractionType.Redirect}
    authenticationRequest={authenticationParameters}
    >
        <React.StrictMode>
          <App/>
        </React.StrictMode>
        
  </MsalAuthenticationTemplate>
  </MsalProvider> ,
 
);

